<template>
  <v-container fluid>
    <v-row>
      <v-col class="col-12">
        <h1>Add contact to {{ customer.name }}</h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="col-2">
        <v-text-field
          label="First Name"
          v-model="contact.first_name"></v-text-field>
      </v-col>
      <v-col class="col-2">
        <v-text-field
          label="Last Name"
          v-model="contact.last_name"></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="col-4">
        <v-text-field
          label="Email"
          v-model="contact.email"></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="col-4">
        <v-text-field
          label="Telephone"
          v-model="contact.tel"></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="col-4">
        <v-btn @click="saveContact">Save Contact</v-btn>
      </v-col>
    </v-row>
    <v-dialog
      v-model="showMessage"
      max-width="400"
    >
      <v-card>
        <v-card-text class="pt-4">
          {{ message }}
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="orange darken-2"
            x-small
            @click="addAnother"
          >
            Add Another
          </v-btn>
          &nbsp;
          <router-link :to="`/customers/view/${customer.id}`">
            <v-btn
              x-small
              color="orange darken-2">
              Back to customer details
            </v-btn>
          </router-link>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>  
</template>

<script>
import axios from '../../axios';

export default {
  name: 'AddContact',
  computed: {
    token() {
      return this.$store.state.token;
    },
  },
  data() {
    return {
      customer: {},
      contact: {},
      message: '',
      showMessage: false,
    };
  },
  methods: {
    addAnother() {
      this.showMessage = false;
      this.contact = {};
    },
    saveContact() {
      const customerId = this.$route.params.id;
      axios.post(`/contacts/add/${customerId}.json?token=${this.token}`, this.contact)
        .then((response) => {
          this.message = response.data.message;
          this.contact = response.data.contact;
          this.showMessage = true;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getCustomer() {
      const customerId = this.$route.params.id;
      axios.get(`/customers/getSingle/${customerId}.json?token=${this.token}`)
        .then((response) => {
          this.customer = response.data.customer;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  mounted() {
    this.getCustomer();
  },
};
</script>
